import React from 'react';
import './entries.css';

function Entries({entries}) {
	const tag = (entries.length == 1) ? 'match' : 'matches'
	return (
		<div className="entries">
			<div className="entriescount">{entries.length} {tag}</div>
			{entries.map(e => renderEntry({...e}))}
		</div>
	);
}

function renderEntry({id, term, definition}) {
	return (
		<div key={id} className="entry">
			<div className="term">{term.join(", ")}</div>
			<div className="definition">{definition}</div>
		</div>
	)
}

export default Entries;
