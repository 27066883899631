import React from 'react';
import Entries from './entries/entries';
import Search from './search/search';
import LetterFilter from './filter/letterfilter';
import Help from './help/help';
import dictionary from './dictionary.json';
import './App.css';

const useSemiPersistentState = (key, initialState) => {
	// const [value, setValue] = React.useState(localStorage.getItem(key) || initialState);
	const [value, setValue] = React.useState(initialState);

	// React.useEffect(
	// 	() => localStorage.setItem(key, value),
	// 	[value]
	// );

	return [value, setValue];
};

function ideaFilter(fx) {
	return ({ term, definition }) => {
		if ((fx.term.length === 0) && (fx.desc.length === 0) && (fx.letters.length === 0))
			return false;

		const matchletter = (fx.letters.length === 0) || (fx.letters.includes(term[0][0]));

		return (
			matchletter
			&&
			term[0].toLowerCase().includes(fx.term.toLowerCase())
			&&
			definition.toLowerCase().includes(fx.desc.toLowerCase())
		);
	}
}

function _removeLetter(letter, letters) {
	const index = letters.indexOf(letter);
	if (index > -1)
		letters.splice(index, 1);
	return letters;
}

function _updateLetterArray(letter, activate, appendMode, letters) {
	if (!activate)
		return _removeLetter(letter, letters);

	if (!appendMode)
		return [letter]; 
	letters.push(letter);
	return letters;
}

function App() {
	const [dicFilter, setDicFilter] = useSemiPersistentState('term', { term: '', desc: '', letters: [] });

	const updateLetterFilter = (letter, activate, appendMode) => {
		const newletters = _updateLetterArray(letter, activate, appendMode, dicFilter.letters);
		const newfilter = { term: dicFilter.term, desc: dicFilter.desc, letters: newletters };
		setDicFilter(newfilter);
	}

	const updateSearchByTerm = e => {
		const newfilter = { term: e.target.value, desc: dicFilter.desc, letters: dicFilter.letters };
		setDicFilter(newfilter);
	}

	const updateSearchByDescription = e => {
		const newfilter = { term: dicFilter.term, desc: e.target.value, letters: dicFilter.letters };
		setDicFilter(newfilter);
	}

	const dic = dictionary;

	const searchedIdeas = dic.filter(ideaFilter(dicFilter));

	return (
		<div>
			<h1>The Dictionary of Received Ideas</h1>

			<LetterFilter dictionary={dic} activeLetters={dicFilter.letters} clickHandler={updateLetterFilter} />

			<Help hasEntries={searchedIdeas.length > 0} />

			<div className="searchbox">
				<Search label="Search Term" search={dicFilter.term} onUpdate={updateSearchByTerm} />
				<Search label="Search Definition" search={dicFilter.desc} onUpdate={updateSearchByDescription} />
			</div>
			
			<Entries entries={searchedIdeas} />
		</div>
	);
}

export default App;
