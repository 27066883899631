import React from 'react';
import './help.css';

function Help({ hasEntries }) {
	const classname = (hasEntries) ? "help hide" : "help show";

	return (
		<div className={classname}>
			Click on a letter (ctrl-click selects multiple) or enter a search term below.			
		</div>
	);
}

export default Help;
