import React from 'react';
import Letter from './letter'
import './letterfilter.css';

function LetterFilter({ dictionary, activeLetters, clickHandler }) {
	const letters = [];

	dictionary
		.map(i => i.term[0])
		.forEach((e, i) => {
			if (!letters.includes(e[0]))
				letters.push(e[0]);
		});

	return (
		<div className="lettersbar">
			{letters.map(letter => renderLetter(letter, activeLetters.includes(letter), clickHandler))}
		</div>
	);
}

function renderLetter(letter, active, clickHandler) {
	return (
		<Letter letter={letter} active={active} clickHandler={clickHandler} />
	)
}

export default LetterFilter;
