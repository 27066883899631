import React from 'react';
import './letterfilter.css';

function Letter(props) {
	let classname = (props.active) ? "letter active" : "letter";
	const wrappedHandler = (e) => props.clickHandler(props.letter, !props.active, e.ctrlKey); 
	return (
		<div key={props.letter} className={classname} onClick={wrappedHandler}>{props.letter}</div>
	);
}

export default Letter;