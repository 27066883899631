import React from 'react';
import './search.css';

function Search({ label, search, type = 'text', onUpdate }) {
	return (
		<>
			<div className="keyvaluepair">
				<div className="key"><label htmlFor="search">{label}</label></div>
				<div className="editentry"><input id="search" type={type} value={search} onChange={onUpdate} /></div>
			</div>
		</>
	);
}

export default Search;
